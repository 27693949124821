<template>
  <div class="nav">
    <div class="nav-header">
      <font-awesome-icon size="2x" :icon="['fas', 'cogs']" />
      <h1>{{navTitle}}</h1>
    </div>

    <div class="nav-wrapper">
      <div class="nav-category">
        <div class="nav-cat-header">
          <font-awesome-icon :icon="['fas', 'th']" />
          <h4>Overview</h4>
        </div>
        <ul>
          <li id="StreamRoomManagement" @click="select($event)" :class="{selected : this.targetId == 'StreamRoomManagement' && menuActive == true}">
            Active Stream Rooms
          </li>
        </ul>
      </div>

      <div v-if="this.$store.state.userClaims.admin || this.$store.state.userClaims.broadcaster" class="nav-category">
        <div class="nav-cat-header">
          <font-awesome-icon :icon="['fas', 'key']" />
          <h4>Manage System</h4>
        </div>
        <ul>
          <li id="ProjectManagement" @click="select($event)" :class="{selected : this.targetId == 'ProjectManagement' && menuActive == true}">Projects</li>
          <li id="UserManagement" @click="select($event)" :class="{selected : this.targetId == 'UserManagement' && menuActive == true}">Users</li>
          <li v-if="this.$store.state.userClaims.admin" id="ActivityTracking" @click="select($event)" :class="{selected : this.targetId == 'ActivityTracking'}">Activity Tracking</li>
          <!-- <li>Services</li> -->
          <!-- <li>Metrics</li> -->
        </ul>
      </div>

      <div class="nav-category streamRoomControl">
        <div class="nav-cat-header streamRoomControl">
          <font-awesome-icon :icon="['fas', 'broadcast-tower']" />
          <h4 class="streamRoomControl">Stream Room Control</h4>
        </div>
        <ul>
          <li id="streamRoomViewersModal" @click="select($event)" :class="{selected : this.targetId == 'streamRoomViewersModal' && menuActive == true}">
            Current Stream Viewers
          </li>
          <li v-if="this.$store.state.userClaims.admin || this.$store.state.userClaims.broadcaster" id="editStreamRoomModal" @click="select($event)" :class="{selected : this.targetId == 'editStreamRoomModal' && menuActive == true}">
            Edit Room Settings
          </li>
        </ul>
      </div>

      <div id="YourSettings" class="nav-category">
        <div class="nav-cat-header">
          <font-awesome-icon :icon="['fas', 'key']" />
          <h4>Your Settings</h4>
        </div>
        <ul>
          <li id="EditUser" @click="select($event)" :class="{selected : this.targetId == 'EditUser'}">User Profile</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

library.add(fas);

export default {
  name: "Navigation",
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      navcomp: "ManageStreamsNav",
      navisManage: true,
      navisAdmin: false,
      targetId: null,
      menuActive: false,
    };
  },
  computed: {
    navTitle() {
      if (this.$store.state.userClaims.viewer) {
        return "Stream Browser";
      } else if (this.$store.state.userClaims.admin) {
        return "Administration";
      } else {
        return "Unknown";
      }
    }
  },
  methods: {
    select(event) {
      if (this.targetId == event.currentTarget.id) {
        this.menuActive = !this.menuActive
      }  else if (this.targetId != event.currentTarget.id) {
        this.menuActive = true;
      }
      console.log(this.menuActive)
      this.targetId = event.currentTarget.id;
      this.$emit("newMenu", this.targetId);
      //console.log(targetId); // returns 'foo'
    },
  },
  created() {
    if (this.$route.name == "AdminPanel") {
      this.navSelComp = "AdminNavSelector";
    } else if (this.$route.name == "ViewerPanel") {
      this.navSelComp = "ViewerNavSelector";
    }
  },
};
</script>

<style lang="scss" scoped>
.nav {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  background-color: #2f323c;
  z-index: 1001;
  min-width: 312px;
  margin-left: auto;
  min-height: calc(100vh - 92px);

  .nav-header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
      margin-left: 10px;
    }
  }

  #yourSettings {
    bottom: 0px;
    display: flex;
    flex-direction: column;
    margin-top: auto;
    margin-bottom: 15px;
  }
  .nav-category {
    border: #17181a 1px solid;
    background-color: #262830;
    width: 85%;
    margin-top: 15px;

    .nav-cat-header.streamRoomControl {
      color: RGB(233, 60, 45);
      h4 {
        font-size: 13px;
        margin: 0px 10px;
        color: RGB(233, 60, 45) !important;
      }
    }
    .nav-cat-header {
      background-color: #1c1d23;
      padding: 10px;
      font-size: 13px;
      display: flex;
      align-items: center;

      h4 {
        font-size: 13px;
        margin: 0px 10px;
      }
    }

    ul {
      margin: 0px;
      padding-left: 15px;
    }
    li {
      font-weight: 700;
      font-size: 13px;
      list-style-type: none;
      padding: 10px 0px;
      cursor: pointer;
    }

    li:hover {
      color:RGB(0, 132, 255);
    }

    li.selected {
      color:RGB(0, 132, 255);
    }
  }

  .nav-wrapper {
    display: flex;
    width: 100%;
    text-align: start;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
    flex-shrink: 0;
    flex-grow: 1;

    // .selected {
    //   background-color: #134ec5;
    // }
  }

  button {
    align-self: center;
  }

  .logout {
    background-color: #2f323c;
    border: 1px solid #9296a1;
    height: 38px;
    width: 110px;
    color: #e1ecff;
    font-weight: 400;
    font-size: 1em;
    margin-right: 5px;
    padding: 0px;
  }

  .logout:hover {
    background-color: #262830;
  }
}

.navfooter {
  width: 100%;
  height: 50px;
  background: #262830;
  margin-top: auto;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  .name-container {
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin-right: 5px;
  }
  p {
    font-size: 0.9em;
    margin-block: 0;
  }
  p:last-of-type {
    font-size: 1em;
  }
}

.nav-category.streamRoomControl {
  border-color: RGB(233, 60, 45);
}
</style>