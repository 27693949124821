<template>
  <vue-slider v-if="active" :contained="true" :height="14" :min="0.01" :max="1" :interval=".001" v-model="chatParticipantVolume"/>
</template>

<script>
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";

export default {
  props: ["params"],
  data() {
    return {
      index: null,
      chatParticipantVolume: null,
      active: true,
    };
  },
  components: {
    VueSlider,
  },
  watch: {
    chatParticipantVolume(newVal, oldVal) {
    this.$store.state.chatPerParticipantVolume[this.index].volume = newVal;
    console.log(this.$store.state.chatPerParticipantVolume[this.index].volume)
    this.$store.state.chatPerParticipantVolume[0] += 1;
    }
  },
  beforeUnmount() {
  },
  created() {
    if (this.params.data.uid == this.$store.state.profileId) {
      this.active = false
      return;
    } else {
  this.index = this.$store.state.chatPerParticipantVolume.findIndex((element) => element.uid == this.params.data.uid)
  this.chatParticipantVolume = this.$store.state.chatPerParticipantVolume[this.index].volume;
    }

  },
  mounted() {
  },
  methods: {
  },
};
</script>



<style lang="scss">
.vti__input {
  height: 28px !important;
  margin: 0px !important;
}

.vti__dropdown {
  margin: 0px !important;
  height: 26px !important;
  width: 50px !important;
  padding: 0px !important;
  margin-right: 5px !important;
}

.vue-tel-input {
  display: flex !important;
  text-align: left !important;
  width: 207px !important;
  margin-bottom: 5px !important;
}

.vti__dropdown-list {
  z-index: 1002 !important;
}
</style>

<style lang="scss" scoped>
.modal {
  display: flex;
  flex-direction: column;
  flex-shrink: 2;
  flex-grow: 3;
  max-width: 360px;
  background-color: #2f323c;
  height: 600px;
  margin-left: 10px;

  .header {
    background-color: #383c49;
    height: 70px;
    display: flex;
    align-items: center;
    color: #e5ecff;
    font-size: 1.5em;
    font-weight: 700;
    padding-left: 25px;

    p {
      text-align: center;
    }

    button {
      align-self: center;
    }
  }
}

.modal-content {
  display: flex;
  flex-direction: column;
  width: calc(100% - 30px);
  padding: 15px 15px;
  height: 100%;
  background-color: #2f323c;

  .createUser {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;
  }

  label {
    font-size: 12px;
    color: RGB(239, 243, 255);
    flex-shrink: 2;
  }

  input {
    height: 28px;
    background-color: #262830;
    border: 1px solid #17181a;
    margin: 2.5px 0px 2.5px 0px;
    color: #eff3ff;
    padding-left: 10px;
    width: 207px;
  }

  #publishToken {
    color: #eff3ff73;
  }

  select {
    height: 28px;
    background-color: #262830;
    border: 1px solid #17181a;
    margin: 0px 0px 5px 0px;
    color: #eff3ff;
    padding-left: 10px;
    width: 207px;
  }

  #camera_preview {
    width: 206px;
  }
  .input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }

  .input#microphone-meter-wrapper {
    background-color: #262830;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    span {
      font-size: 13px;
      font-weight: 400;
      margin-bottom: 5px;
      margin-bottom: 10px;
      margin-top: 10px;
    }

    #tip {
      font-size: 10px;
      font-style: oblique;
      align-self: center;
    }
  }

  button {
    background-color: RGB(0, 132, 255);
    border: none;
    height: 37px;
    width: 100%;
    color: #eff3ff;
    font-size: 12px;
    font-weight: 700;
    cursor: pointer;
    margin-top: auto;
  }

  button:hover {
    background-color: #0084ff;
  }
}

.form-header {
  border-bottom: 1px solid RGB(123, 138, 173);
  margin-bottom: 15px;
  display: flex !important;
}

.form-wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
}

.addUsers {
  height: 160px;
}

.error {
  color: #ad0000 !important;
  font-size: 14px;
}

$checkbox-size: 20px;

input[type="checkbox"].checkbox {
  appearance: none;
  width: $checkbox-size;
  height: $checkbox-size;
  position: relative;
  border-radius: 5px;
  border: #eff3ff 2px solid;
  margin-right: 186px;
  margin-top: 5px;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    color: rgb(178, 199, 255, 0);
    background-color: #383c49;
    border-radius: 5px;
    font: {
      size: 0rem;
      weight: bolder;
    }
    line-height: $checkbox-size;
    text-align: center;
    transition: background-color 350ms 0ms ease-out, color 50ms 50ms ease-in,
      font-size 75ms 150ms ease-out;
  }

  &:checked::after {
    content: "\02714";
    color: #b2c7ff;
    font-size: 15px;
  }
}

.progress-bar {
  float: left;
  width: 0;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #337ab7;
  -webkit-box-shadow: inset 0 -1px 0 rgb(0 0 0 / 15%);
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 15%);
  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
}
.progress-bar {
  width: 100%;
  border-radius: 50px;
  background: #17181a;
  border: #17181a 1px solid;
  overflow: hidden;
  height: 16px;
  margin: 10px 0px;
}

.progress-bar-internal {
  height: 16px;
  background: #32a358;
}
</style>