<template>
  <loading v-if="processing" />
  <div id="chatRoomSettings" class="modal">
    <div class="header"><h2>Chat Settings</h2></div>
    <div class="modal-content">
      <div class="form-wrap background">
        <form class="createUser">
          <div class="form-header">
            <h5>Camera Settings</h5>
          </div>

          <div class="input">
            <label>Camera</label>
            <VueMultiselect
              v-model="cameraSource"
              :options="cameraDropdown"
              :close-on-select="true"
              :clear-on-select="false"
              :allowEmpty="false"
              :canDeselect="false"
              :canClear="false"
              placeholder="Select a camera source"
              label="label"
              track-by="deviceId"
              :input="OnMediaChange"
            />
          </div>

          <div class="input">
            <label>Preview</label>
            <video id="camera_preview" class="camera_preview"></video>
          </div>

          <div class="form-header">
            <h5>Microphone Settings</h5>
          </div>
          <div class="input">
            <label>Microphone</label>
            <VueMultiselect
              v-model="microphoneSource"
              :options="microphoneDropdown"
              :close-on-select="true"
              :clear-on-select="false"
              :allowEmpty="false"
              :canDeselect="false"
              :canClear="false"
              placeholder="Select an audio source"
              label="label"
              track-by="deviceId"
            />
          </div>

          <div class="form-header">
            <h5>Mic Check</h5>
          </div>
          <div class="input" id="microphone-meter-wrapper">
            <span> This is the level of your microphone.</span>
            <div id="microphone_meter" class="progress-bar">
              <div
                id="mic_meter_bar"
                class="progress-bar-internal"
                style=""
              ></div>
            </div>
            <span id="tip">
              To talk while muted in the chat, press and hold spacebar.</span
            >
          </div>
          <div class="input"></div>
          <div v-show="error" class="error">{{ this.errorMsg }}</div>
          <button @click.prevent="closeModal">Save Chat Settings</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/functions";
import {
  timestamp,
  auth,
  rtdb,
  db,
} from "../firebase/firebaseInit.js";
import VueMultiselect from "vue-multiselect";
import "../vue-multiselect/vue-multiselect.css";
import loading from "../Loading.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import _, { map } from "underscore";

// file deepcode ignore VueInvalidComponentName: <please specify a reason of ignoring this>
// file deepcode ignore VueInvalidComponentName: <please specify a reason of ignoring this>
// deepcode ignore VueInvalidComponentName: <please specify a reason of ignoring this>
export default {
  name: "chat_settings",
  props: ["streamRoomId", "menuModal"],
  emits: ["saveChatSettings","closeModal"],
  data() {
    return {
      roomName: "",
      password: "",
      publishToken: "",
      projectName: [],
      uid: "",
      projectList: [],
      error: "",
      errorMsg: "",
      userList: [],
      permittedUsers: [],
      newPermittedUsers: [],
      removedUsers: [],
      processing: false,
      // videoInputs: [],
      // audioInputs: [],
      // audioOutputs: [],
      cameraDropdown: [],
      cameraSource: "",
      microphoneDropdown: [],
      microphoneSource: "",
      audiooutDropdown: [],
      audiooutDestination: "",
      processor: null,
      audioContext: null,
    };
  },
  components: {
    loading,
    VueMultiselect,
  },
  watch: {
    // whenever question changes, this function will run
    cameraSource: function () {
      this.OnMediaChange();
    },
    microphoneSource: function () {
      this.OnMediaChange();
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      roomName: { required },
    };
  },
  beforeUnmount() {
    if (this.processor != null) {
      this.processor.disconnect();
    }
    // if (this.mediaStream != null) {
    // 	this.mediaStream.disconnect();
    // }
    if (this.audioContext != null) {
      this.audioContext.close();
    }

    var video = document.querySelector("#camera_preview");
    if (video.srcObject != null) {
      video.srcObject.getTracks().forEach(function (track) {
        track.stop();
      });
    }
  },
  created() {
    this.init_opts();
  },
  mounted() {
    
  },
  methods: {
    async init_opts() {
      //GET ALL AV DEVICES


      await navigator.mediaDevices.enumerateDevices().then((devices) => {
        // let vis = this.cameraSource;
        // let ais = this.microphoneSource;
        // let aos = this.audiooutDestination;

        devices.forEach((device) => {
          if (device.kind == "videoinput") {
            this.cameraDropdown.push(device);
          } else if (device.kind == "audioinput") {
            this.microphoneDropdown.push(device);
          } else if (device.kind == "audiooutput") {
            this.audiooutDropdown.push(device);
          }
        });
      });

      if (this.$store.state.currentCameraInput == null){
        this.cameraSource = this.cameraDropdown[0];
      } else {
        this.cameraSource = this.$store.state.currentCameraInput
      }
      
      if (this.$store.state.currentMicrophoneInput == null){
      this.microphoneSource = this.microphoneDropdown[0];
      } else {
      this.microphoneSource = this.$store.state.currentMicrophoneInput
      }
    },

    OnMediaChange() {
      function updateMeter(pct) {
        var meter = document.getElementById("mic_meter_bar");
        meter.style.width = pct + "%";
      }

      let vi = this.cameraSource.deviceId;
      let ai = this.microphoneSource.deviceId;
      let ao = this.audiooutDestination.deviceId;

      var constraintsA = {
        audio: { deviceId: { exact: ai } },
      };

      var constraintsV = {
        audio: { deviceId: { exact: ai } },
        video: { deviceId: { exact: vi } },
        width: { min: 640, ideal: 1920, max: 1920 },
        height: { min: 400, ideal: 1080 },
      };

      var video = document.querySelector("#camera_preview");
      if (video.srcObject != null) {
        video.srcObject.getTracks().forEach(function (track) {
          track.stop();
        });
      }

      navigator.mediaDevices
        .getUserMedia({ audio: false, video: true })
        .then(
          async (stream) => {
            navigator.mediaDevices
              .getUserMedia(constraintsV)
              .then((mediaStream) => {
                let video = document.querySelector("#camera_preview");
                video.srcObject = mediaStream;
                video.onloadedmetadata = function (e) {
                  video.play();
                  video.muted = true;
                };

                if (this.processor != null) {
                  this.processor.disconnect();
                }
                // if (mediaStream != null) {
                //   mediaStream.disconnect();
                // }
                if (this.audioContext != null) {
                  this.audioContext.close();
                }

                this.audioContext = new AudioContext();
                let mediaStreamSource =
                  this.audioContext.createMediaStreamSource(mediaStream);
                this.processor = this.audioContext.createScriptProcessor(
                  2048,
                  1,
                  1
                );
                var getAudioTracks = mediaStream.getAudioTracks();

                //mediaStreamSource.connect(audioContext.destination);
                mediaStreamSource.connect(this.processor);
                this.processor.connect(this.audioContext.destination);

                this.processor.onaudioprocess = function (e) {
                  var inputData = e.inputBuffer.getChannelData(0);
                  var inputDataLength = inputData.length;
                  var total = 0;

                  for (var i = 0; i < inputDataLength; i++) {
                    total += Math.abs(inputData[i++]);
                  }

                  var rms = Math.sqrt(total / inputDataLength);
                  updateMeter(rms * 100);
                };
              });
          },
          (e) => {
            navigator.mediaDevices
              .getUserMedia(constraintsA)
              .then((mediaStream) => {
                var video = document.querySelector("#camera_preview");
                video.srcObject = mediaStream;
                video.onloadedmetadata = function (e) {
                  video.play();
                  video.muted = true;
                };

                if (this.processor != null) {
                  this.processor.disconnect();
                }
                // if (mediaStream != null) {
                //   mediaStream.disconnect();
                // }
                if (this.audioContext != null) {
                  this.audioContext.close();
                }

                this.audioContext = new AudioContext();
                mediaStreamSource =
                  this.audioContext.createMediaStreamSource(mediaStream);
                this.processor = this.audioContext.createScriptProcessor(
                  2048,
                  1,
                  1
                );
                var getAudioTracks = mediaStream.getAudioTracks();

                //              mediaStreamSource.connect(audioContext.destination);
                mediaStreamSource.connect(this.processor);
                this.processor.connect(this.audioContext.destination);

                this.processor.onaudioprocess = function (e) {
                  var inputData = e.inputBuffer.getChannelData(0);
                  var inputDataLength = inputData.length;
                  var total = 0;

                  for (var i = 0; i < inputDataLength; i++) {
                    total += Math.abs(inputData[i++]);
                  }

                  var rms = Math.sqrt(total / inputDataLength);
                  updateMeter(rms * 100);
                };
              });
          }
        )
        .catch(function (err) {
          console.log(err.name + ": " + err.message);
        });
    },
    closeModal() {
      this.processing = false;

      this.$emit("saveChatSettings", {"cameraSource":this.cameraSource,"microphoneSource":this.microphoneSource});
    },
  },
};
</script>



<style lang="scss">
.vti__input {
  height: 28px !important;
  margin: 0px !important;
}

.vti__dropdown {
  margin: 0px !important;
  height: 26px !important;
  width: 50px !important;
  padding: 0px !important;
  margin-right: 5px !important;
}

.vue-tel-input {
  display: flex !important;
  text-align: left !important;
  width: 207px !important;
  margin-bottom: 5px !important;
}

.vti__dropdown-list {
  z-index: 1002 !important;
}
</style>

<style lang="scss" scoped>
.modal {
  display: flex;
  flex-direction: column;
  flex-shrink: 2;
  flex-grow: 3;
  max-width: 360px;
  background-color: #2f323c;
  height: 600px;
  margin-left: 10px;

  .header {
    background-color: #383c49;
    height: 70px;
    display: flex;
    align-items: center;
    color: #e5ecff;
    font-size: 1.5em;
    font-weight: 700;
    padding-left: 25px;

    p {
      text-align: center;
    }

    button {
      align-self: center;
    }
  }
}

.modal-content {
  display: flex;
  flex-direction: column;
  width: calc(100% - 30px);
  padding: 15px 15px;
  height: 100%;
  background-color: #2f323c;

  .createUser {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;
  }

  label {
    font-size: 12px;
    color: RGB(239, 243, 255);
    flex-shrink: 2;
  }

  input {
    height: 28px;
    background-color: #262830;
    border: 1px solid #17181a;
    margin: 2.5px 0px 2.5px 0px;
    color: #eff3ff;
    padding-left: 10px;
    width: 207px;
  }

  #publishToken {
    color: #eff3ff73;
  }

  select {
    height: 28px;
    background-color: #262830;
    border: 1px solid #17181a;
    margin: 0px 0px 5px 0px;
    color: #eff3ff;
    padding-left: 10px;
    width: 207px;
  }

  #camera_preview {
    width: 206px;
  }
  .input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }

  .input#microphone-meter-wrapper {
    background-color: #262830;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    span {
      font-size: 13px;
      font-weight: 400;
      margin-bottom: 5px;
      margin-bottom: 10px;
      margin-top: 10px;
    }

    #tip {
      font-size: 10px;
      font-style: oblique;
      align-self: center;
    }
  }

  button {
    background-color: RGB(0, 132, 255);
    border: none;
    height: 37px;
    width: 100%;
    color: #eff3ff;
    font-size: 12px;
    font-weight: 700;
    cursor: pointer;
    margin-top: auto;
  }

  button:hover {
    background-color: #0084ff;
  }
}

.form-header {
  border-bottom: 1px solid RGB(123, 138, 173);
  margin-bottom: 15px;
  display: flex !important;
}

.form-wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
}

.addUsers {
  height: 160px;
}

.error {
  color: #ad0000 !important;
  font-size: 14px;
}

$checkbox-size: 20px;

input[type="checkbox"].checkbox {
  appearance: none;
  width: $checkbox-size;
  height: $checkbox-size;
  position: relative;
  border-radius: 5px;
  border: #eff3ff 2px solid;
  margin-right: 186px;
  margin-top: 5px;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    color: rgb(178, 199, 255, 0);
    background-color: #383c49;
    border-radius: 5px;
    font: {
      size: 0rem;
      weight: bolder;
    }
    line-height: $checkbox-size;
    text-align: center;
    transition: background-color 350ms 0ms ease-out, color 50ms 50ms ease-in,
      font-size 75ms 150ms ease-out;
  }

  &:checked::after {
    content: "\02714";
    color: #b2c7ff;
    font-size: 15px;
  }
}

.progress-bar {
  float: left;
  width: 0;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #337ab7;
  -webkit-box-shadow: inset 0 -1px 0 rgb(0 0 0 / 15%);
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 15%);
  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
}
.progress-bar {
  width: 100%;
  border-radius: 50px;
  background: #17181a;
  border: #17181a 1px solid;
  overflow: hidden;
  height: 16px;
  margin: 10px 0px;
}

.progress-bar-internal {
  height: 16px;
  background: #32a358;
}
</style>