<template>
  <div :id="id" v-bind:class="{sysNotification : type == 'join' || type == 'disconnect' }" class="message-wrapper">
    
      <div class="message" :class="{sent : messageType == 'sent', received : messageType == 'received', none : messageType == 'sysNotification'}">
        <slot />

      </div>
      <span v-if="!sender && type !== 'join' && type !== 'disconnect'" class="nametag">{{ name }}</span>
      <span v-if="sender" :class="{nameright : sender}" class="nametag">You</span>

  </div>
          <!-- <div class="sentDate">
          {{sendDate}}
          </div> -->
</template>

<script>
export default {
  emits: ["scrollToMessage"],
  props: {
    id: {type: String, default:''},
    name: { type: String, default: '' },
    sender: { type: Boolean, default: false },
    sendDate: { type: String, default: ''},
    type: {type: String, default: ''},
    userId: {type: String, default: ''}
  },
  computed: {
    // a computed getter
    messageType() {
      let statement = "blah";
      if (this.type == "message") {
        if (this.sender == true) {
          statement = "sent"
          return statement;
        } else if (this.sender == false) {
          statement = "received"
          return statement;
        }
      } else if (this.type == "join" || this.type == "disconnect") {
        statement = "sysNotification";
        return statement;
      }
      return statement;
    }
},
mounted() {
  this.scrollToMessage(this.id)
},
methods: {
  scrollToMessage(id) {
        this.$emit('scrollToMessage', id)
      }
}
}
</script>

<style lang="scss" >
.message-container {
  margin: 10px;
}
.received {
    background-color: RGB(177, 207, 150);
    padding: 8px;
    border-radius: 10px 10px 10px 0px;
    margin-right: auto !important;
}
.sent {
    background-color: RGB(150, 179, 207);
    // width: -webkit-max-content;
    // width: -moz-max-content;
    // width: max-content;
    padding: 8px;
    border-radius: 10px 10px 0px 10px;
    margin-left: auto !important;
}
.message {
    display: flex;
    justify-content: center;
    color: #17181A;
}
.message-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-bottom: 5px;
    color: #eff3ff;
    }

.message.none {
  color: #eff3ff;
}

.sysNotification {
    display: flex;
    background-color: #ffffff08;
    margin: 10px 0px;
    justify-content: center;
    padding: 5px 0px;
    color: RGB(239, 243, 255);
}
.sentDate{
  font-size: 5px;
}

.nametag{
font-size: 12px;
}
.nameright{
  margin-left:auto;
}
</style>