<template>
  <div class="streamRoomViewers">
    <div class="header">
      <h2>Current Viewers</h2>
      <div v-if="this.$store.state.userClaims.admin || this.$store.state.userClaims.broadcaster" class="actions">

        <button
          id="kickViewer"
          class="button"
          @click.prevent="kickViewer"
        >
          Kick
        </button>

        <button
          id="banUser"
          class="button"
          @click.prevent="banViewer"
        >
          Ban
        </button>

        <!-- <button
          id="inviteUser"
          class="button"
          @click.prevent="selectAction($event)"
          :class="{
            active: modalMode == 'inviteUserModal' && UserModalActive == true,
          }"
        >
          Invite
        </button> -->
      </div>

      </div>
    

    <div class="modalBody">
      <div class="datawrapper">
        <ag-grid-vue
          style="width: 100%; height: 100%"
          class="ag-theme-balham-dark datatable"
          :columnDefs="columnDefs"
          :rowData="rowData"
          :rowSelection="rowSelection"
          :defaultColDef="defaultColDef"
          :detailCellRendererParams="detailCellRendererParams"
          @grid-ready="onGridReady"
          @grid-size-changed="sizeToFit"
          @selection-changed="onSelectionChanged"
          :sortingOrder="sortingOrder"
          :pagination="pagination"
          :paginationPageSize="paginationPageSize"
        >
        </ag-grid-vue>
      </div>
    </div>
    </div>
  <transition appear name="slide-fade">
    <component
      :streamRoomId="streamRoomId"
      :is="modalMode"
      v-if="StreamRoomModalActive"
      v-on:close-modal="closeStreamRoomModal"
    />
  </transition>
</template>

<script>
import { defineAsyncComponent, reactive } from "vue";
import { AgGridVue } from "ag-grid-vue3";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { timestamp, auth, rtdb, db } from "../firebase/firebaseInit.js";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";
import gridVolumeControl from "../streamRoom/gridVolumeControl.vue";


export default {
  props: {
    currentViewers: Object,
    streamRoomId: String,
    chatPerParticipantVolume: Array,
  },
  data() {
    return {
      columnDefs: null,
      rowData: [],
      rowSelection: null,
      gridApi: null,
      columnApi: null,
      defaultColDef: { resizable: true },
      modalMode: "createStreamRoomModal",
      StreamRoomModalActive: false,
      inviteCreateStreamRoomModalActive: false,
      sortingOrder: null,
      pagination: null,
      paginationPageSize: null,
      detailCellRendererParams: null,
      selectedViewer: null,
    };
  },
  components: {
    AgGridVue,
    VueSlider,
    gridVolumeControl
  },
  watch: {
    currentViewers(newVal, oldVal) {
      // console.log("VIEWER CHANGE")
    
    setTimeout(() => {   
    this.rowData = [];
    Object.keys(newVal).forEach(key => {
      let buffer = null;
      // console.log(newVal[key])
      buffer = newVal[key]
      buffer.uid = key;
      this.rowData.push(buffer)
    }); 
      }, 3000);    
    return;
    },
    chatPerParticipantVolume(newVal, oldVal) {
      // console.log(newVal)
    }
  },
  methods: {
    banViewer() {
      if (this.selectedViewer == this.$store.state.profileId) {
                console.log("CANT KICK YOURSELF")
        return;
      } else {
        console.log("KICKED")
        // console.log(this.streamRoomId)
     
      db.collection("users").doc(this.selectedViewer).collection("roomAccess").doc(this.streamRoomId).delete().then(() => {
        db.collection("streamRooms").doc(this.streamRoomId).collection("permittedUsers").doc(this.selectedViewer).delete().then(() => {
              console.log("Document successfully deleted!");
            }).catch((error) => {
              console.error("Error removing document: ", error);
            });
    })
    .catch((error) => {
        console.log("Error getting documents: ", error);
    });

      let roomQuorum = rtdb
        .ref("streamRoomLogs/" + this.streamRoomId + "/quorum")
        .child(this.selectedViewer);
      roomQuorum.update({
        kickUser: true,
      });
      }

    },
    kickViewer() {
      if (this.selectedViewer == this.$store.state.profileId) {
                console.log("CANT KICK YOURSELF")
        return;
      } else {
        console.log("KICKED")
        // console.log(this.streamRoomId)
      let roomQuorum = rtdb
        .ref("streamRoomLogs/" + this.streamRoomId + "/quorum")
        .child(this.selectedViewer);
      roomQuorum.update({
        kickUser: true,
      });
      }

    },
    onSelectionChanged() {
      const selectedRows = this.gridApi.getSelectedRows();
      this.selectedViewer =
        selectedRows.length === 1 ? selectedRows[0].uid : "";
      // console.log(this.selectedViewer);
      // console.log("state: " + this.selectedViewer);
    },
    sizeToFit() {
      this.gridApi.sizeColumnsToFit();
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    closeStreamRoomModal() {
      this.rowData = [];
      this.getAllStreamRooms();
      this.StreamRoomModalActive = !this.StreamRoomModalActive;
    },
  },
  created() {
    // console.log(this.chatPerParticipantVolume)
    this.detailCellRendererParams = {
      refreshStrategy: 'rows',
    }
    this.rowSelection = "single";
    this.sortingOrder = ["desc", "asc", null];
    // enables pagination in the grid
    this.pagination = true;
    // sets 10 rows per page (default is 100)
    this.paginationPageSize = 10;
    this.defaultColDef = {
      resizable: true,
    };
  },
  mounted() {
    // console.log(this.currentViewers);

    Object.keys(this.currentViewers).forEach(key => {
      let buffer = null;
      // console.log(this.currentViewers[key])
      buffer = this.currentViewers[key]
      buffer.uid = key;
      this.rowData.push(buffer)
    }); 
    if (this.$store.state.userClaims.viewer) {
          this.columnDefs = [
      {
        field: "userName",
        filter: true,
        floatingFilter: true,
        width: 120,
        sort: "asc",
        sortable: true,
      },
      {
        field: "email",
        filter: true,
        floatingFilter: true,
        width: 120,
        sortable: true,
      },
      {
        field: "volume",
        width: 120,
        cellRenderer: "gridVolumeControl",
      },
      //  { field: "uid", filter: true, floatingFilter: true },
    ];
    } else if (this.$store.state.userClaims.admin || this.$store.state.userClaims.broadcaster) {
    this.columnDefs = [
      {
        field: "userName",
        filter: true,
        floatingFilter: true,
        width: 120,
        sort: "asc",
        sortable: true,
      },
      {
        field: "email",
        filter: true,
        floatingFilter: true,
        width: 120,
        sortable: true,
      },
      {
        field: "ipAddress",
        filter: true,
        floatingFilter: true,
        width: 120,
        sortable: true,
      },
      {
        field: "volume",
        width: 120,
        cellRenderer: "gridVolumeControl",
      },
      //  { field: "uid", filter: true, floatingFilter: true },
    ];
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-balham-dark.css";

.streamRoomViewers {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  flex-grow: 2;
  width: 100%;
  max-width: 800px;
  background-color: #262830;
  height: 600px;
  transition: all 0.5s ease-out;
}

.header {
  background-color: RGB(47, 50, 60);
  height: 70px;
  display: flex;
  align-items: center;
  color: #e5ecff;
  font-size: 1.5em;
  font-weight: 700;
  padding-left: 25px;

  h2 {
    font-size: 24px;
    font-weight: 300;
  }
}

.actions {
  display: flex;
  align-items: center;
  justify-items: center;
  margin-left: auto;
  margin-right: 30px;

  .button {
    position: relative;
    padding: 0px 15px;
    max-height: 37px;
    min-height: 33px;
    font-size: 12px;
    font-weight: 400;
    background-color: RGBA(68, 75, 90, 0.2);
    border: 1px solid #444b5a;
    color: inherit;
    cursor: pointer;
    min-width: 33px;
    margin-left: 10px;
  }

  #banUser {
    background-color: RGBA(233, 60, 45, 0.2);
    border: 1px solid RGB(233, 60, 45);
  }

  #banUser:hover {
    background-color: RGB(233, 60, 45, 0.8);
  }

  .button:hover {
    background-color: #505874;
  }
  .active {
    background-color: RGBA(239, 243, 255, 0.05) !important;
    border-color: RGB(239, 243, 255) !important;
  }
}

.modalBody {
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  padding: 15px 15px;
}

.datawrapper {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  align-self: center;

  .datatable {
    width: calc(100% - 30px);
    height: calc(100% - 10px);
    border: 1px solid #000000;
  }
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
