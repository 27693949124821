<template>
  <div id="streamRoom" class="streamroom">
    <div class="roomContainer">
      <div class="topbar">
        <span
          >{{ projectName }} -- {{ roomName }} --
          {{ viewerCount }} Viewers</span
        >
      </div>
      <splitpanes horizontal style="height: calc(100vh - 94px)">
        <pane size="65">
          <div class="videoplayercontainer">
            <watermark :class="{ hidden: !watermark }"></watermark>

            <div style="align-self: center" :class="{ hidden: streamLive }">
              This stream room has not started yet.
            </div>
            <div id="webrtcplayer"></div>
          </div>
        </pane>
        <pane size="10">
          <div ref="meet" id="meet" class="jitsichat"></div>
        </pane>
      </splitpanes>

      <div class="bottombar">
        <div class="main-controls">
          <div class="icon-wrapper">
            <Popper hover openDelay="200" closeDelay="100" content="Chat">
              <font-awesome-icon
                :class="{ activebutton: chatActive }"
                @click="chatToggle"
                :icon="['fas', 'comments']"
              />
            </Popper>

            <Popper hover openDelay="200" closeDelay="100" content="Raise Hand">
              <font-awesome-icon
                @click.prevent="raiseHand"
                :icon="['fas', 'hand-sparkles']"
              />
            </Popper>

            <Popper
              placement="top"
              arrow
              hover
              offsetDistance="75"
              openDelay="200"
              closeDelay="100"
              content="Stream Volume"
            >
              <div @mouseover="showStreamVolume" id="streamVolumeMute">
                <div
                  @mouseover="showStreamVolume"
                  @mouseleave="streamVolumeHover = false"
                  id="streamVolume"
                  class="hoverpopup"
                  v-if="streamVolumeHover"
                >
                  <vue-slider
                    :contained="true"
                    :height="14"
                    v-model="streamVolume"
                  />
                </div>

                <font-awesome-icon
                  @click.prevent="mutePlayer"
                  :icon="streamMuteIcon"
                  :class="{ muteActive: streamVolume == 0 }"
                />
              </div>
            </Popper>

            <Popper
              placement="top"
              arrow
              hover
              openDelay="200"
              closeDelay="100"
              content="Full Screen"
            >
              <font-awesome-icon
                style="font-size: 35px"
                @click.prevent="fullscreenPlayer"
                :icon="['fas', 'expand-arrows-alt']"
                :class="{ activebutton: fullscreen }"
              />
            </Popper>

            <Popper
              placement="top"
              arrow
              hover
              openDelay="200"
              closeDelay="100"
              content="Navigation Menu"
            >
              <font-awesome-icon
                :class="{ activebutton: navActive }"
                @click="navToggle"
                :icon="['fas', 'cog']"
              />
            </Popper>

            <Popper
              hover
              openDelay="200"
              closeDelay="100"
              content="This Does Nothing"
            >
              <font-awesome-icon :icon="['fas', 'volume-mute']" />
            </Popper>

            <Popper
              placement="top"
              arrow
              hover
              openDelay="200"
              closeDelay="100"
              content="Current Viewers"
            >
              <font-awesome-icon
                id="streamRoomViewersModal"
                @click="select($event)"
                :icon="['fas', 'user-plus']"
                :class="{
                  activebutton:
                    menuModal == 'streamRoomViewersModal' && menuActive,
                }"
              />
            </Popper>
          </div>
        </div>
        <div class="chat-controls">
          <Popper
            hover
            openDelay="200"
            closeDelay="100"
            content="Chat Settings"
          >
            <font-awesome-icon
              id="ChatSettingsModal"
              @click="select($event)"
              :icon="['fas', 'user-cog']"
              :class="{
                activebutton: menuModal == 'ChatSettingsModal' && menuActive,
              }"
            />
          </Popper>

          <Popper
            hover
            openDelay="200"
            closeDelay="100"
            content="Mute Micrphone"
          >
            <font-awesome-icon
              :class="{
                muteActive: micMuteStatus,
                muteInactive: !micMuteStatus,
              }"
              @click.prevent="muteMic"
              :icon="micMuteIcon"
            />
          </Popper>

          <Popper hover openDelay="200" closeDelay="100" content="Mute Camera">
            <font-awesome-icon
              :class="{
                muteActive: camMuteStatus,
                muteInactive: !camMuteStatus,
              }"
              @click.prevent="muteCam"
              :icon="camMuteIcon"
            />
          </Popper>

          <Popper
            placement="top"
            arrow
            hover
            offsetDistance="75"
            openDelay="200"
            closeDelay="100"
            content="Chat Volume"
          >
            <div @mouseover="showChatVolume" id="chatVolumeMute">
              <div
                @mouseover="showChatVolume"
                @mouseleave="chatVolumeHover = false"
                id="chatVolume"
                class="hoverpopup"
                v-if="chatVolumeHover"
              >
                <vue-slider
                  :contained="true"
                  :height="14"
                  :min="0"
                  :max="1"
                  :interval="0.01"
                  v-model="chatVolume"
                />
              </div>

              <font-awesome-icon
                @click.prevent="muteChat"
                :icon="streamMuteIcon"
                :class="{ muteActive: chatVolume == 0 }"
              />
            </div>
          </Popper>
        </div>
        <div class="right-controls">
          <span class="user-loggedin" @click.prevent="logOut">
            <font-awesome-icon size="2x" :icon="['fas', 'sign-out-alt']" />
            Logged in as {{ this.$store.state.profileFirstName }}
          </span>
          <div
            @click.prevent="disableStream"
            :class="{ streamDisabled: !streamLive }"
            class="stream-status"
          >
            {{ streamStatus }}
          </div>
        </div>
      </div>
      <!-- <component :is="menuModal"></component> -->
    </div>
    <!-- <Nav @newMenu="changeMenu" /> -->
  </div>
  <div v-if="chatActive" class="left-bar">
    <streamRoomChat :streamRoomId="streamRoomId" />
  </div>
  <div
    v-if="navActive || menuActive || chatActive"
    id="streamRoomMenus"
    class="menuContainer"
    :class="{
      chatSettingsContainer: menuModal == 'ChatSettingsModal',
      centerMenus: navActive == false,
    }"
  >
    <component
      :menuModal="menuModal"
      :streamRoomId="streamRoomId"
      :userId="uid"
      :currentViewers="currentViewers"
      v-if="menuActive"
      :is="menuModal"
      :chatPerParticipantVolume="chatPerParticipantVolume"
      v-on:saveChatSettings="saveChatSettings"
      v-on:close-modal="closeStreamRoomModal"
    ></component>
  </div>
  <div v-if="navActive" class="right-bar">
    <Nav :streamRoomId="streamRoomId" @newMenu="changeMenu" />
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/functions";
import { defineAsyncComponent } from "vue";
import Nav from "../components/streamRoom/streamRoomNavigation.vue";
import {
  timestamp,
  auth,
  rtdb,
  db,
} from "../components/firebase/firebaseInit.js";
import OvenPlayer from "../components/ovenmediaplayer/ovenplayer.js";
// import UserManagement from "../components/userManagement/UserManagement.vue";
// import ProjectManagement from "../components/projectManagement/ProjectManagement.vue";
// import StreamRoomManagement from "../components/streamRoomManagement/StreamRoomManagement.vue";
import ChatSettingsModal from "../components/streamRoom/chatSettingsModal.vue";
import streamRoomChat from "../components/streamRoom/streamRoomChat.vue";
import streamRoomViewersModal from "../components/streamRoom/StreamRoomViewersModal.vue";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";
import watermark from "../components/streamRoom/watermark.vue";
import Popper from "vue3-popper";
import { Splitpanes, Pane } from "splitpanes";
import "splitpanes/dist/splitpanes.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);

const editStreamRoomModal = defineAsyncComponent(() =>
  import("../components/streamRoomManagement/EditStreamRoomModal.vue")
);

const EditUser = defineAsyncComponent(() =>
  import("../components/EditUser.vue")
);

const UserManagement = defineAsyncComponent(() =>
  import('../components/userManagement/UserManagement.vue')
)

const ProjectManagement = defineAsyncComponent(() =>
  import('../components/projectManagement/ProjectManagement.vue')
)

const StreamRoomManagement = defineAsyncComponent(() =>
  import('../components/streamRoomManagement/StreamRoomManagement.vue')
)

const ActivityTracking = defineAsyncComponent(() =>
  import('../components/activityTracking/ActivityTracking.vue')
)

export default {
  name: "StreamRoom",
  components: {
    Nav,
    FontAwesomeIcon,
    UserManagement,
    ProjectManagement,
    StreamRoomManagement,
    editStreamRoomModal,
    ChatSettingsModal,
    VueSlider,
    streamRoomChat,
    streamRoomViewersModal,
    watermark,
    Popper,
    Splitpanes,
    Pane,
    EditUser,
    ActivityTracking
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      streamRoomId: this.$store.state.targetStream,
      menuModal: "UserManagement",
      projectName: "",
      roomName: "",
      publishToken: null,
      viewerCount: 0,
      streamLive: null,
      player: null,
      reloadTimer: null,
      api: null,
      autoReloadInterval: 2000,
      streamVolume: 100,
      streamVolumePreMuted: 100,
      streamMuted: false,
      streamVolumeHover: false,
      chatVolume: 1,
      chatVolumePreMuted: 1,
      chatMuted: false,
      chatVolumeHover: false,
      chatPerParticipantVolume: [],
      fullscreen: false,
      navActive: false,
      chatActive: false,
      menuActive: false,
      micMuteStatus: true,
      camMuteStatus: true,
      chats: [],
      currentViewers: [],
      quorumData: null,
      quorumLeader: false,
      heartbeat: setInterval(() => this.sendHeartbeat(), 20000),
      chatId: null,
      chatDisplayName: null,
      ipAddress: this.$store.state.currentIpAddress,
      watermark: false,
      cleaningUp: false,
      unsubscribeStreamRoom: null,
      streamServer: {
        id: null,
        name: null,
      },
      streamServerConfig: null,
      chatServer: {
        id: null,
        name: null,
      },
      chatServerConfig: null,
      joinTime: null,
    };
  },

  watch: {
    // currentViewers(newVal, oldVal) {
    //   this.addToCurrentViewers();
    // },
    cppv(value, oldValue) {
      if (this.api == null) {
        return;
      }
      if (this.chatVolume == 0) {
        this.$store.state.chatPerParticipantVolume.forEach((element) => {
          if (element.uid == this.$store.state.profileId) {
            return;
          } else {
            this.api.executeCommand(
              "setParticipantVolume",
              element.chatId,
              0.001
            );
          }
        });
      } else {
        this.$store.state.chatPerParticipantVolume.forEach((element) => {
          if (element.uid == this.$store.state.profileId) {
            return;
          } else {
            this.api.executeCommand(
              "setParticipantVolume",
              element.chatId,
              element.volume * this.chatVolume
            );
          }
        });
      }
    },
    streamVolume(newVal, oldVal) {
      if (this.player == null) {
        return;
      } else {
        this.player.setVolume(newVal);
        if (newVal > 0) {
          this.streamMuted = false;
        } else if (newVal == 0) {
          this.streamMuted = true;
        }
      }
    },
    chatVolume(newVal, oldVal) {
      if (this.api == null) {
        return;
      }
      if (this.chatVolume == 0) {
        this.$store.state.chatPerParticipantVolume.forEach((element) => {
          if (element.uid == this.$store.state.profileId) {
            return;
          } else {
            this.api.executeCommand(
              "setParticipantVolume",
              element.chatId,
              0.001
            );
          }
        });
      } else {
        // Object.keys(this.quorumData).forEach(key => {
        this.$store.state.chatPerParticipantVolume.forEach((element) => {
          if (element.uid == this.$store.state.profileId) {
            return;
          } else {
            this.api.executeCommand(
              "setParticipantVolume",
              element.chatId,
              element.volume * newVal
            );
            // console.log(element);
          }
        });
      }
    },
    quorumData(newVal, oldVal) {
      if (oldVal !== null) {
        Object.keys(oldVal).forEach((key) => {
          if (key in newVal == false) {
            const index = this.$store.state.chatPerParticipantVolume.findIndex(
              (element) => element.uid == key
            );
            this.$store.state.chatPerParticipantVolume.splice(index, 1);
          }
        });
      }

      Object.keys(newVal).forEach((key) => {
        if (newVal[key].chatId == "") {
          return;
        } else if (
          this.$store.state.chatPerParticipantVolume.some(
            (e) => e.uid === key && e.chatId !== newVal[key].chatId
          )
        ) {
          const index = this.$store.state.chatPerParticipantVolume.findIndex(
            (element) => element.uid == key
          );
          this.$store.state.chatPerParticipantVolume[index].chatId =
            newVal[key].chatId;
        } else if (
          this.$store.state.chatPerParticipantVolume.some(
            (e) => e.uid === key && e.chatId == newVal[key].chatId
          )
        ) {
          return;
        } else {
          let buffer = {
            uid: key,
            chatId: newVal[key].chatId,
            volume: 1,
          };
          this.$store.state.chatPerParticipantVolume.push(buffer);
          if (this.api !== null) {
            this.api.executeCommand(
              "setParticipantVolume",
              buffer.chatId,
              buffer.volume * this.chatVolume
            );
          }
        }
      });
    },
    streamLive(newVal, oldVal) {
      if (newVal == false) {
        this.unLoadPlayer();
      } else if (this.streamServerConfig == null) {
        return;
      } else if (newVal) {
        this.loadPlayer();
      }
    },
  },
  computed: {
    // a computed getter
    cppv() {
      return this.$store.state.chatPerParticipantVolume[0];
    },
    streamStatus() {
      let statement = "OFFLINE";
      if (this.streamLive == false) {
        statement = "OFFLINE";
        return statement;
      } else {
        statement = "LIVE";
        return statement;
      }
    },
    micMuteIcon() {
      let status = "microphone";
      if (this.micMuteStatus == false) {
        status = "microphone";
      } else if (this.micMuteStatus) {
        status = "microphone-slash";
      }
      return status;
    },
    camMuteIcon() {
      let status = "video";
      if (this.camMuteStatus == false) {
        status = "video";
      } else if (this.camMuteStatus) {
        status = "video-slash";
      }
      return status;
    },
    streamMuteIcon() {
      let status = "volume-up";
      if (this.streamMuted == false) {
        status = "volume-up";
      } else if (this.streamMuted) {
        status = "volume-mute";
      }
      return status;
    },
    chatMuteIcon() {
      let status = "volume-up";
      if (this.chatMuted == false) {
        status = "volume-up";
      } else if (this.chatMuted) {
        status = "volume-mute";
      }
      return status;
    },
  },
  async created() {

    this.joinTime = Date.now();

// Check if user has permission to join room
    await db
      .collection("streamRooms")
      .doc(this.streamRoomId)
      .collection("permittedUsers")
      .doc(this.$store.state.profileId)
      .onSnapshot(async (doc) => {

        if (doc.exists == true || this.$store.state.userClaims.admin) {



    let roomQuorum = rtdb
      .ref("streamRoomLogs/" + this.streamRoomId + "/quorum")
      .child(this.$store.state.profileId);

    roomQuorum.set({
      userName:
        this.$store.state.profileFirstName +
        " " +
        this.$store.state.profileLastName,
      email: this.$store.state.profileEmail,
      ipAddress: this.ipAddress,
      chatId: "",
      chatDisplayName: "",
      joinTime: this.joinTime,
      heartbeat: Date.now(),
      kickuser: false,
    });

    let chatJoin = rtdb
      .ref("streamRoomLogs/" + this.streamRoomId + "/chats")
      .push();

    let chatDisconnect = rtdb
      .ref("streamRoomLogs/" + this.streamRoomId + "/chats")
      .push();

    chatDisconnect.onDisconnect().set({
      type: "disconnect",
      ipAddress: this.ipAddress,
      user:
        this.$store.state.profileFirstName +
        " " +
        this.$store.state.profileLastName,
      message:
        this.$store.state.profileFirstName +
        " " +
        this.$store.state.profileLastName +
        " has left this room.",
      sendDate: firebase.database.ServerValue.TIMESTAMP,
    });

    chatJoin.set({
      type: "join",
      ipAddress: this.ipAddress,
      user:
        this.$store.state.profileFirstName +
        " " +
        this.$store.state.profileLastName,
      message:
        this.$store.state.profileFirstName +
        " " +
        this.$store.state.profileLastName +
        " has joined this room.",
      sendDate: firebase.database.ServerValue.TIMESTAMP,
    });

    let retreiveQuorumData = rtdb.ref(
      "streamRoomLogs/" + this.streamRoomId + "/quorum"
    );
    retreiveQuorumData.on("value", (snapshot) => {
      console.log("test")
      const data = snapshot.val();
      if (data[this.$store.state.profileId].kickuser == true) {
        if (this.$store.state.userClaims.admin) {
          this.$router.push({ name: "Admin Panel" });
          console.log("Going to Admin Panel");
        } else if (this.$store.state.userClaims.broadcaster) {
          this.$router.push({ name: "Stream Browser" });
          console.log("Going to Stream Browser");
        } else if (this.$store.state.userClaims.viewer) {
          this.$router.push({ name: "Stream Browser" });
          console.log("Going to Stream Browser");
        } else {
          this.$router.push({ name: "Login" });
        }
      }
      this.quorumData = data;
      // console.log(this.quorumData);
      let removedViewers = [];
      this.currentViewers = data;
      this.viewerCount = Object.keys(this.currentViewers).length;
      Object.keys(data).forEach((key) => {
        if (data[key].heartbeat < Date.now() - 40000) {
          removedViewers.push(key);
          delete data[key];
        }
      });

      let earliestJoinTime = Object.keys(data).sort(
        (a, b) => data[a].joinTime - data[b].joinTime
      )[0];

      if (earliestJoinTime == this.$store.state.profileId) {
        this.quorumLeader = true;
        // console.log("I AM THE LEADER");
      }

      if (this.quorumLeader) {
        removedViewers.forEach((element) => {
          rtdb
            .ref("streamRoomLogs/" + this.streamRoomId + "/quorum/" + element)
            .remove();
          let index = removedViewers.indexOf(element);
          removedViewers.splice(index, 1);
          // console.log(removedViewers);
        });

        // let currentViewersArray = Object.keys(data);
        // let currentViewers = rtdb
        //   .ref("streamRoomLogs/" + this.streamRoomId)
        //   .child("currentViewers");
        // currentViewers.set({
        //   currentViewers: currentViewersArray,
        // });
      }
    });

    // deepcode ignore NoEffectExpression: <Call a heartbeat function>
    this.heartbeat;

    this.unsubscribeStreamRoom = await db
      .collection("streamRooms")
      .doc(this.streamRoomId)
      .onSnapshot(async (doc) => {
        let joinMessage = false;
        // console.log(doc.data());
        this.roomName = doc.data().roomName;
        this.publishToken = doc.data().publishToken;
        this.projectName = doc.data().projectName;
        this.streamLive = doc.data().streamLive;
        this.watermark = doc.data().enableWatermark;
        // this.currentViewers = doc.data().currentViewers;
        // console.log("room name: " + this.roomName);
        this.$store.state.currentStreamRoom = doc.data().roomName;
        this.$store.state.currentLocation = doc.data().roomName;
        this.$store.state.currentStreamRoomId = doc.id;

        if (
          doc.data().chatServer.id != this.chatServer.id ||
          this.chatServer.id == null
        ) {
          if (this.api != null) {
            this.api.dispose();
          }
          this.chatServer = doc.data().chatServer;
          await db
            .collection("serviceServers")
            .doc(this.chatServer.id)
            .get()
            .then(async (doc) => {
              this.chatServerConfig = doc.data();
              // console.log(
              //   "wss://" +
              //     this.chatServerConfig.url +
              //     ":" +
              //     this.chatServerConfig.port
              // );

              this.domain =
                this.chatServerConfig.url + ":" + this.chatServerConfig.port;
              this.options = {
                roomName: this.streamRoomId,
                userInfo: {
                  displayName:
                    this.$store.state.profileFirstName +
                    " " +
                    this.$store.state.profileLastName,
                },
                subject: "",
                width: "100%",
                parentNode: document.querySelector("#meet"),
                allow: "camera",
                configOverwrite: {
                  toolbarButtons: [
                    // "chat",
                    // "raisehand",
                    "stats",
                    "shortcuts",
                    "mute-everyone",
                  ],
                  TOOLBAR_ALWAYS_VISIBLE: false,
                  ENFORCE_NOTIFICATION_AUTO_DISMISS_TIMEOUT: 2000,
                  VERTICAL_FILMSTRIP: false,
                },
              };

              this.loadChat();
            });
        } else if (doc.data().chatServer.id == this.chatServer.id) {
          return;
        }

        if (
          doc.data().streamServer.id != this.streamServer.id ||
          this.streamServer.id == null
        ) {
          this.streamServer = doc.data().streamServer;
          await db
            .collection("serviceServers")
            .doc(this.streamServer.id)
            .get()
            .then(async (doc) => {
              this.streamServerConfig = doc.data();
              // console.log(
              //   "wss://" +
              //     this.streamServerConfig.url +
              //     ":" +
              //     this.streamServerConfig.tcpPorts.min +
              //     "/app/" +
              //     this.publishToken +
              //     "?transport=" +
              //     this.streamServerConfig.defaultTransport
              // );
              if (this.player && this.streamLive == true) {
                if (this.player.getState() == "playing") {
                  return;
                }
              } else if (this.streamLive === false) {
                this.unLoadPlayer();
                console.log("UNLOAD");
                return;
              } else if (this.streamLive === true) {
                this.unLoadPlayer();
                console.log("LOAD");
                this.loadPlayer();
              }
            });
        } else if (doc.data().streamServer.id == this.streamServer.id) {
          return;
        }

        if (joinMessage == false) {
          let logAccess = rtdb.ref("masterLog/").push();

          logAccess.set({
            type: "join",
            user: this.$store.state.profileEmail,
            userId: this.$store.state.profileId,
            streamRoom: this.streamRoomId,
            streamRoomName: this.roomName,
            projectName: this.projectName,
            ipAddress: this.ipAddress,
            sendDate: firebase.database.ServerValue.TIMESTAMP,
          });

          joinMessage = true;
        } else if (joinMessage == true) {
          return;
        }
      });

    window.addEventListener("click", (event) => this.focusIframe());
        
        } else {
          if (this.$store.state.userClaims.admin) {
            this.$router.push({ name: "Admin Panel" });
          } else if (this.$store.state.userClaims.broadcaster) {
            this.$router.push({ name: "Stream Browser" });
          } else if (this.$store.state.userClaims.viewer) {
            this.$router.push({ name: "Stream Browser" });
          } else {
            this.$router.push({ name: "Login" });
          }
        }
      });
//

  },
  mounted() {

  },
  unmounted() {
    if (this.cleaningUp) {
      return;
    } else {
      this.cleanUp();
    }
  },
  methods: {
    cleanUp() {
      // let logAccessDisconnect = rtdb
      // .ref("masterLog/");

      let logAccessDisconnect = rtdb.ref("masterLog/").push();

      let roomQuorumRemove = rtdb
        .ref("streamRoomLogs/" + this.streamRoomId + "/quorum")
        .child(this.$store.state.profileId);

      let roomQuorumDisconnect = rtdb.ref(
        "streamRoomLogs/" + this.streamRoomId + "/quorum"
      );

      let chatDisconnect = rtdb.ref(
        "streamRoomLogs/" + this.streamRoomId + "/chats"
      );

      this.cleaningUp = true;
      this.focusIframe();
      window.removeEventListener("click", (event) => this.focusIframe());

      this.unsubscribeStreamRoom();
      console.log("Unmount 1");

      this.api.dispose();
      console.log("Unmount 2");

      roomQuorumDisconnect.off();
      roomQuorumRemove.remove();
      console.log("Unmount 3");

      logAccessDisconnect.off();
      logAccessDisconnect.set({
        type: "disconnect",
        user: this.$store.state.profileEmail,
        userId: this.$store.state.profileId,
        streamRoom: this.streamRoomId,
        streamRoomName: this.roomName,
        projectName: this.projectName,
        ipAddress: this.ipAddress,
        sendDate: firebase.database.ServerValue.TIMESTAMP,
      });
      console.log("Unmount 4");

      chatDisconnect.off();
      console.log("Unmount 5");

      clearInterval(this.heartbeat);
      console.log("Unmount 6");
    },
    logOut() {
      this.cleanUp();

      this.$store.state.triggerLogout = true;

      //  let logAccess = rtdb.ref("masterLog/").push();

      // logAccess.set({
      //   type: "logout",
      //   user: this.$store.state.profileEmail,
      //   userId: this.$store.state.profileId,
      //   ipAddress: this.$store.state.currentIpAddress,
      //   deviceId: this.$store.state.deviceId,
      //   // currentDevice: this.currentDevice,
      //   // geoLocation: this.geoLocation,
      //   streamRoom: this.streamRoomId,
      //   streamRoomName: this.roomName,
      //   projectName: this.projectName,
      //   sendDate: firebase.database.ServerValue.TIMESTAMP,
      // });

      // auth.signOut().then(() => {
      //   firebase.auth().onAuthStateChanged((userAuth) => {
      //     if (!userAuth) {
      //       this.$router.replace("/");
      //       this.$store.dispatch("logOut");
      //     }
      //   });
      // });
    },
    clearScreen() {
      this.navActive = false;
      this.chatActive = false;
      this.menuActive = false;
    },
    sendHeartbeat() {
      let roomQuorum = rtdb
        .ref("streamRoomLogs/" + this.streamRoomId + "/quorum")
        .child(this.$store.state.profileId);
      roomQuorum.update({
        userName:
          this.$store.state.profileFirstName +
          " " +
          this.$store.state.profileLastName,
        email: this.$store.state.profileEmail,
        ipAddress: this.ipAddress,
        joinTime: this.joinTime,
        heartbeat: Date.now(),
        chatId: this.chatId,
        chatDisplayName: this.chatDisplayName,
      });
    },
    muteMic() {
      this.api.executeCommand("toggleAudio");
    },
    muteCam() {
      this.api.executeCommand("toggleVideo");
    },
    select(event) {
      // console.log("MENUSELECT :" + event.currentTarget.id);
      if (this.menuModal == event.currentTarget.id) {
        this.menuActive = !this.menuActive;
      } else if (this.menuModal !== event.currentTarget.id) {
        this.menuModal = event.currentTarget.id;
        this.menuActive = true;
      }
      //console.log(targetId); // returns 'foo'
    },
    changeMenu(value) {
      if (this.menuModal == value) {
        this.menuActive = !this.menuActive;
      } else if (this.menuModal !== value) {
        this.menuModal = value;
        this.menuActive = true;
      }
      // console.log(this.streamRoomId);
    },
    navToggle() {
      this.navActive = !this.navActive;
      if (this.menuActive == true && this.navActive == false) {
        this.menuActive = false;
        console.log("DON'T CLOSE");
      } else if (this.menuActive == true && this.navActive == true) {
        this.menuActive = true;
        console.log("CLOSE");
      }
    },
    chatToggle() {
      this.chatActive = !this.chatActive;
    },
    async showStreamVolume() {
      if (this.streamVolumeHover == false) {
        this.streamVolumeHover = true;
      }
    },
    async showChatVolume() {
      if (this.chatVolumeHover == false) {
        this.chatVolumeHover = true;
      }
    },
    fullscreenPlayer() {
      if (
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement
      ) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
          this.fullscreen = false;
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
          this.fullscreen = false;
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
          this.fullscreen = false;
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
          this.fullscreen = false;
        }
      } else {
        let streamRoomElement = document.querySelector("#streamRoom");
        this.fullscreen = true;
        if (streamRoomElement.requestFullscreen) {
          streamRoomElement.requestFullscreen();
        } else if (streamRoomElement.mozRequestFullScreen) {
          streamRoomElement.mozRequestFullScreen();
        } else if (streamRoomElement.webkitRequestFullscreen) {
          streamRoomElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        } else if (streamRoomElement.msRequestFullscreen) {
          streamRoomElement.msRequestFullscreen();
        }
      }
    },
    loadChat() {
      this.api = new JitsiMeetExternalAPI(this.domain, this.options);
      console.log("CHAT LOAD");

      this.api.addListener("videoConferenceJoined", (listener) => {
        let iframe = this.api.getIFrame();
        let chatParticipants = this.api.getParticipantsInfo();

        // var firstLoad = localStorage.getItem('firstload');
        if (listener != null) {
          this.api.executeCommand("setTileView", "enabled: true");

          // let vi = localStorage.getItem('currentvideoinput');
          // let ai = localStorage.getItem('currentaudioinput');
          // let ao = localStorage.getItem('currentaudiooutput');
          // let jitsi_displayName = localStorage.getItem('displayName');

          // api.setVideoInputDevice(vi);
          // api.setAudioInputDevice(ai);
          // api.setAudioOutputDevice(ao);
          // api.executeCommand('displayName', jitsi_displayName);

          // console.log(chatParticipants);

          setTimeout(() => {
            this.api.isAudioMuted().then((muted) => {
              console.log(muted);
              if (muted == false) {
                this.api.executeCommand("toggleAudio");
              }
            })
            .catch((error) => {

            });
          }, 1500);

          this.api.addListener("tileViewChanged", (listener) => {
            // console.log("toggletileview: " + listener.enabled);
            this.api.executeCommand("setTileView", "enabled: true");
          });

          this.api.addListener("audioMuteStatusChanged", (listener) => {
            this.micMuteStatus = listener.muted;
          });

          this.api.addListener("videoMuteStatusChanged", (listener) => {
            this.camMuteStatus = listener.muted;
            // console.log("CAMERA STATUS: " + this.camMuteStatus);
          });

          // this.api.executeCommand('displayName', this.$store.state.profileFirstName + " " + this.$store.state.profileLastName);

          let arr = this.api.getParticipantsInfo();
          let obj = arr.find((o) => o.formattedDisplayName.includes("(me)"));
          this.chatId = obj.participantId;
          this.chatDisplayName = obj.displayName;
          // console.log(obj.participantId);
          this.sendHeartbeat();
        }
      });
    },
    loadPlayer() {
      console.log("LOAD PLAYER");
      if (this.player) {
        this.player.remove();
        this.player = null;
      }

      this.player = OvenPlayer.create("webrtcplayer", {
        autoStart: true,
        controls: false,
        expandFullScreenUI: false,
        sources: [
          {
            file:
              "wss://" +
              this.streamServerConfig.url +
              ":" +
              this.streamServerConfig.tcpPorts.min +
              "/app/" +
              this.publishToken + 
              // "?uid=" + this.$store.state.profileId + "&rid=" + this.streamRoomId +
              "_webrtc?transport=tcp",
            type: "webrtc",
            label: "TCP",
          },
        ],
        webrtcConfig: {
          playoutDelayHint:
            this.streamServerConfig.webrtcConfig.playoutDelayHint,
        },
      });

      this.player.once("error", (e) => {
        console.log(e);

        if (this.reloadTimer) {
          clearInterval(this.reloadTimer);
          this.reloadTimer = null;
        }

        this.reloadTimer = setTimeout(() => {
          this.unLoadPlayer();
          this.loadPlayer();
        }, this.autoReloadInterval);
      });
    },
    unLoadPlayer() {
      if (this.reloadTimer) {
        clearInterval(this.reloadTimer);
        this.reloadTimer = null;
      }

      if (this.player) {
        this.player.remove();
        this.player = null;
      }
    },
    reloadPlayer() {
      if (this.player) {
        this.unLoadPlayer();
      } else {
        this.loadPlayer();
      }
    },
    mutePlayer() {
      if (this.streamMuted == false) {
        this.streamVolumePreMuted = this.streamVolume;
        this.streamVolume = 0;
        this.streamMuted = true;
      } else if (this.streamMuted == true) {
        this.streamVolume = this.streamVolumePreMuted;
        this.streamMuted = false;
      }
    },
    muteChat() {
      if (this.chatMuted == false) {
        this.chatVolumePreMuted = this.chatVolume;
        this.chatVolume = 0;
        this.chatMuted = true;
      } else if (this.chatMuted == true) {
        this.chatVolume = this.chatVolumePreMuted;
        this.chatMuted = false;
      }
    },
    disableStream() {
      if (
        this.$store.state.userClaims.admin ||
        this.$store.state.userClaims.broadcaster
      ) {
        db.collection("streamRooms").doc(this.streamRoomId).update({
          streamLive: !this.streamLive,
        });
      }
    },
    raiseHand() {
      this.api.executeCommand("toggleRaiseHand");
    },
    saveChatSettings(chatSettings) {
       console.log(chatSettings.cameraSource.label);
       this.$store.state.currentCameraInput = chatSettings.cameraSource
       this.$store.state.currentMicrophoneInput = chatSettings.microphoneSource
      this.api.setVideoInputDevice(chatSettings.cameraSource.label);
      this.api.setAudioInputDevice(chatSettings.microphoneSource.label);
      this.closeStreamRoomModal();
    },
    closeStreamRoomModal() {
      this.menuActive = !this.menuActive;
    },
    focusIframe() {
      // console.log("TRIGGERED");
      if (
        this.menuActive ||
        this.chatActive ||
        this.navActive ||
        this.cleaningUp
      ) {
        return;
      } else {
        document.querySelector('[id^="jitsiConferenceFrame"]').focus();
      }
    },
  },
};
</script>

<style lang="scss">
.op-ratio {
  padding-bottom: 0px !important;
}
.editStreamRoomMargins {
  margin-left: auto !important;
  margin-right: 10px;
}
.vue-slider-process {
  background-color: #32a358;
  border-radius: 15px;
  transition: background-color 0.3s;
}
.vue-slider-rail {
  background-color: #17181a;
  border-radius: 15px;
  transition: background-color 0.3s;
}
.vue-slider-dot-handle {
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #eff3ff;
  border: 2px solid #eff3ff;
  box-sizing: border-box;
  transition: box-shadow 0.3s, border-color 0.3s;
}

.op-wrapper.ovenplayer {
  position: relative !important;
  z-index: 1 !important
}
</style>

<style lang="scss" scoped>
.chatSettingsContainer {
  width: 100% !important;
}

.streamroom {
  display: flex;
  justify-items: center;
  align-items: center;
  max-width: 100vw;
  height: 100%;
}
.menuContainer {
  background: rgba(0, 0, 0, 0.336);
  position: absolute;
  height: 100vh;
  width: calc(100vw - 320px);
  top: 0;
  z-index: 60;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
}

.centerMenus {
  width: 100vw !important;
}

.roomContainer {
  min-width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0;
  flex-grow: 0;

  .topbar {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
    min-width: 100%;
    background-color: RGB(28, 29, 35);
    color: RGB(239, 243, 255);
    font-size: 16px;
    flex: 0;
    z-index: 100;
  }

  .videoplayercontainer {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: #000;
    justify-content: center;
    font-weight: 300;
    font-size: 35px;
    position: relative;
  }
  .jitsichat {
    background-color: RGB(28, 29, 35);
    height: 100%;
    width: 100%;
    flex-shrink: 0;
  }

  .bottombar {
    flex-shrink: 0;
    min-height: 52px;
    width: 100%;
    background-color: #262830;
    display: flex;
    flex: 0;
    z-index: 100;

    .activebutton {
      color: RGB(0, 132, 255);
    }

    .main-controls {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      flex: 1;
      flex-basis: 40%;
      justify-content: space-around;
      padding: 0px 10px;

      .muteActive {
        color: RGB(215, 73, 56);
      }

      .icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 400px;
        justify-content: space-around;
        // margin-left: calc(150px + 339px);

        .svg-inline--fa {
          font-size: 23px;
          cursor: pointer;
        }
      }
    }
    .hoverpopup {
      position: fixed;
      padding: 10px 20px;
      bottom: 57px;
      background: #262830;
      border-radius: 8px;
      border: 1px solid #1c1d23;
      width: 150px;
      margin-left: -85px;
    }
    .chat-controls {
      background-color: #1c1d23;
      height: 100%;
      width: 150px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 0px 10px;

      .muteActive {
        color: RGB(215, 73, 56);
      }

      .muteInactive {
        color: RGB(50, 163, 88);
      }
      .svg-inline--fa {
        font-size: 25px;
        cursor: pointer;
      }
    }

    .right-controls {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 339px;
      height: 100%;
      background-color: #383c49;
      padding: 0px 10px;

      .user-loggedin {
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .svg-inline--fa {
          margin-right: 5px;
          cursor: pointer;
        }
      }

      .stream-status {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;
        width: 120px;
        background-color: RGB(215, 73, 56);
        color: RGB(239, 243, 255);
        font-weight: 500;
        font-size: 14px;
        margin-left: auto;
        cursor: pointer;
      }

      .streamDisabled {
        background-color: RGB(56, 60, 73) !important;
        color: RGB(239, 243, 255) !important;
        border: 1px RGB(239, 243, 255) solid;
      }
    }
  }
  .hidden {
    display: none;
  }
}
.right-bar {
  z-index: 70;
  position: absolute;
  right: 0px;
  top: 40px;
}
.left-bar {
  z-index: 100;
  position: absolute;
  left: 0px;
  top: 40px;
}

:deep(.popper) {
  background: #333333;
  padding: 10px;
  border-radius: 10px;
  color: #fff;
  font-weight: bold;
}

:deep(.popper #arrow::before) {
  background: #333333;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
  background: #333333;
}

.inline-block {
  border: 0px !important;
  margin: 0px !important;
}


</style>