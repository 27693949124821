<template>
<div class="watermark">
  <span style="
    font-size: 3vh;
    font-weight: 400;
    line-height: 3vh;
">sStream</span>
  <span style="
    font-size: 4vh;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 3vh;
">{{firstName}}</span>
  <span style="
    font-size: 4vh;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 3vh;
">{{lastName}}</span>
  <span style="
    font-size: 2vh;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 2vh;
">{{hours}}:{{minutes}}:{{seconds}} UTC - {{year}} / {{month}} / {{day}} </span>
  <span style="
    font-size: 2vh;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 2vh;
">{{ipAddress}}</span>
  <span style="
    font-size: 1.5vh;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 2vh;
">{{email}}</span>
</div>
</template>

<script>

export default {
  data() {
    return {
      firstName: null,
      lastName: null,
      email: null,
      hours: 0,
      minutes: 0,
      seconds: 0,
      year: 0,
      month: 0,
      day: 0,
    };
  },
  components: {
  },
  watch: {
  },
  computed: {
    ipAddress () {
      return this.$store.state.currentIpAddress;
    },
  },
  beforeUnmount() {
  },
  created() {
    this.firstName = this.$store.state.profileFirstName;
    this.lastName = this.$store.state.profileLastName;
    this.email = this.$store.state.profileEmail;


  },
  mounted() {
    this.setTime()
    this.setDate()
  },
  methods: {
    setDate () {
      const date = new Date()
      this.year = date.getUTCFullYear();
      this.month = date.getUTCMonth();
      this.day = date.getUTCDate();
      this.month++
    },
    setTime () {
      setInterval(() => {
        const date = new Date()
        this.hours = date.getUTCHours()
        this.minutes = this.checkSingleDigit(date.getUTCMinutes())
        this.seconds = this.checkSingleDigit(date.getUTCSeconds())
      }, 1000)
    },
    checkSingleDigit (digit) {
      return ('0' + digit).slice(-2)
    }
  },
};
</script>



<style lang="scss">
.watermark {
    opacity: .6;
    z-index:50;
    position: absolute;
    bottom: 10%;
    right: 15%;
    margin: auto;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>