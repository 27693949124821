<template>
  <div class="chat">
    <div class="chat-header">
      <font-awesome-icon size="2x" :icon="['fas', 'comments']" />
      <h1>Chat</h1>
    </div>

    <div class="chat-wrapper">
      <div class="chat-category">
      <message
        v-for="{ id, type, user, message, sendDate, userId } in messages"
        :key="id"
        :id='id'
        :name="user"
        :type="type"
        :sendDate="sendDate"
        :sender="userId === this.$store.state.profileId"
        @scrollToMessage="scrollToMessage"
      >
        {{ message }}
      </message>
      </div>
      <form @submit.prevent="sendMessage" style="
    width: 95%;
    flex-grow: 1;
    display: flex;
    margin-top: 10px;"
    >
        <textarea 
          type="text" 
          v-model="inputMessage" 
          placeholder="Write a message..."
          style="
    width: 325px;
    height: 83px;
    margin-right: 5px;
    resize: none;
    background-color: #252830;
    color: white;
    font-family: 'Roboto', sans-serif;
"
        @keypress="pressed" />
        <input 
          type="submit" 
          value="Send"
          style="
    width: 90px;" />
      </form>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent, ref, watch, nextTick } from "vue";
import {
  timestamp,
  auth,
  rtdb,
  db,
} from "../firebase/firebaseInit.js";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import message from "./message.vue"

library.add(fas);

export default {
  name: "streamRoomChat",
  components: {
    FontAwesomeIcon,
    message
  },
  props: ["streamRoomId"],
  data() {
    return {
      targetId: null,
      menuActive: false,
      messages: [],
      inputMessage: "",
    };
  },
  methods: {
    scrollToMessage(e) {
      const element = document.getElementById(e);
      element.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
      },
    sendMessage() {
      const chatMessage = rtdb.ref("streamRoomLogs/" + this.streamRoomId + "/chats").push();

      if (this.inputMessage === "" || this.inputMessage === null) {
        return;
      }
      const message = {
        type: "message",
        user: this.$store.state.profileFirstName + " " + this.$store.state.profileLastName,
        userId: this.$store.state.profileId,
        message: this.inputMessage,
        sendDate: Date(),
      }
      chatMessage.set(message);
      this.inputMessage = "";
    },
    pressed(e) {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault()
                this.sendMessage()
            }
        }
  },
  mounted() {
    console.log(this.streamRoomId)
        rtdb.ref("streamRoomLogs/" + this.streamRoomId + "/chats").on("value", (snapshot) => {
        const data = snapshot.val();
        this.messages = [];
        Object.keys(data).forEach(key => {
          this.messages.push({
            id: key,
            userId: data[key].userId,
            type: data[key].type,
            user: data[key].user,
            message: data[key].message,
            sendDate: data[key].sendDate
          });
        });
        
      });
      console.log(this.messages)
  },
};
</script>

<style lang="scss" >
.chat {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  background-color: #2f323c;
  z-index: 1001;
  min-width: 360px;
  max-width: 360px;
  margin-left: auto;
  max-height: calc(100vh - 92px);
  min-height: calc(100vh - 92px);

  .chat-header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
      margin-left: 10px;
    }
  }

  #yourSettings {
    bottom: 0px;
    display: flex;
    flex-direction: column;
    margin-top: auto;
    margin-bottom: 15px;
  }
  .chat-category {
 background-color: #2f323c;
    width: 95%;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    height: calc(100vh - 300px);
    // max-height: calc(100vh - 300px);
    position: relative;
    align-items: center;
    padding: 5px;


    .chat-cat-header.streamRoomControl {
      color: RGB(233, 60, 45);
      h4 {
        font-size: 13px;
        margin: 0px 10px;
        color: RGB(233, 60, 45) !important;
      }
    }
    .chat-cat-header {
      background-color: #1c1d23;
      padding: 10px;
      font-size: 13px;
      display: flex;
      align-items: center;

      h4 {
        font-size: 13px;
        margin: 0px 10px;
      }
    }

    ul {
      margin: 0px;
      padding-left: 15px;
    }
    li {
      font-weight: 700;
      font-size: 13px;
      list-style-type: none;
      padding: 10px 0px;
      cursor: pointer;
    }

    li:hover {
      color:RGB(0, 132, 255);
    }

    li.selected {
      color:RGB(0, 132, 255);
    }
  }

  .chat-wrapper {
    display: flex;
    position: relative;
    width: 100%;
    text-align: start;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
    flex-shrink: 0;
    flex-grow: 1;
    height: 100%;

    // .selected {
    //   background-color: #134ec5;
    // }
  }

  button {
    align-self: center;
  }

  .logout {
    background-color: #2f323c;
    border: 1px solid #9296a1;
    height: 38px;
    width: 110px;
    color: #e1ecff;
    font-weight: 400;
    font-size: 1em;
    margin-right: 5px;
    padding: 0px;
  }

  .logout:hover {
    background-color: #262830;
  }
}

.chatfooter {
  width: 100%;
  height: 50px;
  background: #262830;
  margin-top: auto;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  .name-container {
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin-right: 5px;

    
  }
  p {
    font-size: 0.9em;
    margin-block: 0;
  }
  p:last-of-type {
    font-size: 1em;
  }
}

input {
    background-color: #262830;
    border: 1px solid #17181a;
    color: #eff3ff;
}

.chat-category.streamRoomControl {
  border-color: RGB(233, 60, 45);
}
</style>